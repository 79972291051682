var prodcat = prodcat || {};

window.site = site || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  // Drupal.behaviors.notifyMeV1.setEvents();
  $(window).on('notifyMe::show', function (e, skuData, skuBaseId) {
    Drupal.behaviors.notifyMeV1.show(skuData, skuBaseId);
  });
  $(window).on('notifyMe::hide', function (e, skuData) {
    Drupal.behaviors.notifyMeV1.hide(skuData);
  });
  $(window).on('notifyMe::toggleAddToBag', function (e, visibility, skuData) {
    Drupal.behaviors.notifyMeV1.toggleAddToBag(visibility, skuData);
  });
  $(document).on('inv_status_display:updated', '.js-product', function (e) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product),
      skuBaseId = $statusList.data('sku-base-id'),
      skuData = prodcat.data.getSku(skuBaseId),
      undef;

    $(window).trigger('notifyMe::toggleAddToBag', ['show', skuData]);
    $(window).trigger('notifyMe::hide', [skuData]);
    if (skuData !== undef) {
      var isValidPreorderSku = Drupal.behaviors.notifyMeV1.isPreorderSku(skuData);

      switch (skuData.INVENTORY_STATUS) {
        case 2: // Temp OOS Status.
          if ($('.product__notify-me', $product).data('show-basic-oos-notify') === 1) {
            // LOP : Basic.
            if (skuData.LIFE_OF_PRODUCT === 2) {
              $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
            }
          }

          // Displaying Notify me and Available xx/xx info for perorder sku
          if (isValidPreorderSku) {
            $soldoutPreorderBtn = $product.find('.soldout-preorder').hide();
            $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
            var $preorderMessageEle = $product.find('.js-inv-status-preorder');

            $statusList.show();
            $('.js-inv-status-' + skuData.INVENTORY_STATUS).hide();
            Drupal.behaviors.notifyMeV1.setPreorderMessage(
              $preorderMessageEle,
              'pre_order_available_tos',
              skuData.RELEASE_DATE,
              false
            );
            Drupal.behaviors.notifyMeV1.setPreorderFlag(skuData.PRODUCT_ID, true);
          }
          break;
        case 3: // Coming Soon Status.
          // Limited Life & Basic has "notify me".
          if (skuData.LIFE_OF_PRODUCT != 3) {
            $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
          }
          break;
        case 7: // Sold Out Status.
          if (
            $('.product__notify-me', $(this)).data('show-basic-sold-out-notify') === 1 ||
            isValidPreorderSku
          ) {
            // Only Life of Product Basic gets "notify me" and also for pre-order sku.
            if (skuData.LIFE_OF_PRODUCT === 2 || isValidPreorderSku) {
              $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  Drupal.behaviors.notifyMeV1 = {
    attach: function (context) {
      var self = this;
    },
    show: function (skuData, skuBaseId) {
      var self = this;
      var $product = prodcat.ui.getSkuScopedProductEl(skuData);
      var $notifyMeAlertToShow = $product.find('.js-notify-me-alert'),
        bisType = '';

      if ($notifyMeAlertToShow.length > 0) {
        var isValidPreorderSku = Drupal.behaviors.notifyMeV1.isPreorderSku(skuData);

        switch (skuData.INVENTORY_STATUS) {
          case 2:
            $notifyMeAlertToShow.show();
            startSignup();
            $(window).trigger('notifyMe::toggleAddToBag', ['hide', skuData]);
            break;
          case 3:
            bisType = '&NOT_READY=1';
            $notifyMeAlertToShow.show();
            startSignup();
            $(window).trigger('notifyMe::toggleAddToBag', ['hide', skuData]);
            break;
          case 7:
            if (isValidPreorderSku) {
              $notifyMeAlertToShow.show();
              startSignup();
              $(window).trigger('notifyMe::toggleAddToBag', ['hide', skuData]);
            } else {
              $notifyMeAlertToShow.hide();
              $(window).trigger('notifyMe::toggleAddToBag', ['show', skuData]);
            }
            break;
          default:
            break;
        }
      }
      function startSignup() {
        if (site.client.isMobile) {
          self.bindNotifyMeLink($notifyMeAlertToShow, skuBaseId, bisType);
        } else {
          $('.mobile-notify-me').hide();
          self.bindNotifyMeLink($notifyMeAlertToShow, skuBaseId, bisType);
        }
      }
    },
    hide: function (skuData) {
      var $product = prodcat.ui.getSkuScopedProductEl(skuData);
      var $notifyMeAlertToShow = $product.find('.js-notify-me-alert');

      if ($notifyMeAlertToShow.length > 0) {
        $notifyMeAlertToShow.hide();
      }
    },
    toggleAddToBag: function (visibility, skuData) {
      var self = this;
      var $products = prodcat.ui.getSkuScopedProductEl(skuData);

      $products.each(function () {
        var $product = $(this);
        var $addToBagBtn = $product.find('.js-add-to-cart');
        var $soldoutPreorderBtn = $product.find('.soldout-preorder');
        var $avaialblePreorderBtn = $product.find('.tos-preorder');
        var $statusList = $product.find('.js-inv-status-list');
        var $notifyMeAlertToShow = $product.find('.js-notify-me-alert');
        var display_soldout_cta = Drupal.settings.product_display.display_soldout_cta;
        var isValidPreorderSku = self.isPreorderSku(skuData);
        var preorderInventoryChange =
          typeof Drupal.settings.bb_product.pre_order_inventory !== 'undefined'
            ? Drupal.settings.bb_product.pre_order_inventory
            : '';
        var $preorderNotifyMe = $product.find('.product__notify-me');

        self.setPreorderFlag(skuData.PRODUCT_ID, false);
        $soldoutPreorderBtn.hide();
        $avaialblePreorderBtn.hide();
        if (!isValidPreorderSku) {
          $product.find('.js-inv-status-preorder').hide();
        }
        $addToBagBtn.html(site.translations.product.product_cta_add_to_bag);
        if (
          visibility === 'hide' ||
          (skuData.INVENTORY_STATUS === 7 && !display_soldout_cta) ||
          (skuData.INVENTORY_STATUS === 7 && isValidPreorderSku)
        ) {
          if ($addToBagBtn.length > 0) {
            $addToBagBtn.hide();
          }
          if (
            $addToBagBtn.length > 0 &&
            $statusList.length > 0 &&
            skuData.INVENTORY_STATUS === 7 &&
            display_soldout_cta
          ) {
            $addToBagBtn.show();
            $statusList.show();
          } else if (
            $statusList.length > 0 &&
            skuData.INVENTORY_STATUS === 7 &&
            !display_soldout_cta
          ) {
            $statusList.show();
          } else if ($statusList.length > 0 && skuData.INVENTORY_STATUS === 3) {
            $statusList.show();
          } else if ($statusList.length > 0) {
            $statusList.hide();
          }
          if (isValidPreorderSku && skuData.INVENTORY_STATUS === 7) {
            $addToBagBtn.hide();
            if (
              $addToBagBtn.length > 0 &&
              $addToBagBtn.closest('.js-quickshop-container').length > 0
            ) {
              $soldoutPreorderBtn.show();
              $soldoutPreorderBtn.html(site.translations.product.preorder);
            } else if (preorderInventoryChange && $addToBagBtn) {
              $addToBagBtn.show();
              $addToBagBtn.html(site.translations.product.preorder);
              $preorderNotifyMe.addClass('preorder_sold_out');
            }
            self.setPreorderFlag(skuData.PRODUCT_ID, true);
            if ($statusList.length > 0) {
              var $preorderMessageEle = $statusList.find('.js-inv-status-preorder');

              $statusList.find('.js-inv-status-' + skuData.INVENTORY_STATUS).hide();
              Drupal.behaviors.notifyMeV1.setPreorderMessage(
                $preorderMessageEle,
                'pre_order_available_soldout',
                skuData.RELEASE_DATE,
                false
              );
              $statusList.show();
            }
          }
        } else if (skuData.INVENTORY_STATUS === 2 && isValidPreorderSku) {
          if (
            $addToBagBtn.length > 0 &&
            $addToBagBtn.closest('.js-quickshop-container').length > 0
          ) {
            $addToBagBtn.show();
            $addToBagBtn.addClass('button--disabled');
            $addToBagBtn.data('disabled', true);
            $addToBagBtn.html(site.translations.product.preorder);
          } else if ($addToBagBtn.length > 0) {
            $addToBagBtn.hide();
          }
          self.setPreorderFlag(skuData.PRODUCT_ID, true);
        } else if (
          visibility === 'show' &&
          skuData.INVENTORY_STATUS === 7 &&
          display_soldout_cta &&
          !isValidPreorderSku
        ) {
          $addToBagBtn.hide();
          $soldoutPreorderBtn.show();
        } else {
          if ($addToBagBtn.length > 0) {
            $addToBagBtn.show();
          }
          if (isValidPreorderSku && skuData.INVENTORY_STATUS === 1) {
            $addToBagBtn.html(site.translations.product.preorder);
            self.setPreorderFlag(skuData.PRODUCT_ID, true);
            var $preorderMessageEle = $product.find('.js-inv-status-preorder');

            Drupal.behaviors.notifyMeV1.setPreorderMessage(
              $preorderMessageEle,
              'pre_order_ship_by_message',
              skuData.RELEASE_DATE,
              true
            );
          }
          if ($statusList.length > 0) {
            $statusList.show();
          }
        }
        // If Notify Me button is present and product is TOS then hide Add to bag button
        if (
          skuData.INVENTORY_STATUS === 2 &&
          $notifyMeAlertToShow.css('display') !== 'none' &&
          $addToBagBtn.length > 0
        ) {
          $addToBagBtn.hide();
          $statusList.show();
        }
      });
    },
    bindNotifyMeLink: function ($notifyMeAlertToShow, skuBaseId, bisType) {
      $notifyMeAlertToShow
        .find('a')
        .unbind()
        .click(function (e, link) {
          e.preventDefault();
          $(link).unbind();
          var file =
            '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuBaseId + '&REQUEST_TYPE=BIS' + bisType;
          var options;
          var isCr22 = Drupal.settings.global_variables && Drupal.settings.global_variables.cr22;
          if (isCr22) {
            options = {
              includeBackground: false,
              includeCloseLink: true,
              hideOnClickOutside: true,
              top: 0,
              fixed: true,
              left: 0,
              height: '100%',
              className: 'waitlist-overlay'
            };
          } else {
            options = {
              includeBackground: false,
              includeCloseLink: true,
              hideOnClickOutside: true,
              width: '500px',
              height: '370px'
            };
          }

          options.content =
            '<iframe class="waitlist-iframe-wrapper" scrolling="no" frameborder="0" src="' +
            file +
            '"></iframe>';
          generic.overlay.launch(options);
        });
    },
    isPreorderSku: function (skuData) {
      var isValidPreorderSku = false;
      var curDate = new Date(skuData.responseDate);
      var relDate = new Date(skuData.responseDate);

      if (skuData.RELEASE_DATE) {
        var releaseSkuDate = '' + skuData.RELEASE_DATE; // Test Date '170621';

        relDate.setFullYear(
          parseInt('20' + releaseSkuDate.substr(0, 2)),
          parseInt(releaseSkuDate.substr(2, 2)) - 1,
          parseInt(releaseSkuDate.substr(4, 2))
        );
        var flag = skuData.MISC_FLAG;

        if (curDate.getTime() < relDate.getTime() && flag === 15) {
          isValidPreorderSku = true;
        }
      }

      return isValidPreorderSku;
    },
    setPreorderMessage: function ($ele, trKey, dateStr, calculateShipDays) {
      if (dateStr) {
        var releaseSkuDateStr = '' + dateStr;
        var releaseSkuDate = new Date();

        releaseSkuDate.setFullYear(
          parseInt('20' + releaseSkuDateStr.substr(0, 2)),
          parseInt(releaseSkuDateStr.substr(2, 2)) - 1,
          parseInt(releaseSkuDateStr.substr(4, 2))
        );
        if (calculateShipDays) {
          var shipDays = parseInt(site.translations.product.pre_order_ship_days);
          var days = releaseSkuDate.getDate();

          if (!isNaN(shipDays)) {
            days += shipDays;
            releaseSkuDate.setDate(days);
          }
        }
        var releaseYear = releaseSkuDate.getFullYear();
        var releaseDay = ('0' + releaseSkuDate.getDate()).slice(-2);
        var releaseMonth = ('0' + (releaseSkuDate.getMonth() + 1)).slice(-2);
        var preorderDateFormat =
          typeof Drupal.settings.global_variables !== 'undefined'
            ? Drupal.settings.global_variables.pre_order_date_format
            : '';
        var releaseDateReplace = '';

        switch (preorderDateFormat) {
          case 'YYYY/MM/DD':
            releaseDateReplace = releaseYear + '/' + releaseMonth + '/' + releaseDay;
            break;
          case 'DD/MM':
            releaseDateReplace = releaseDay + '/' + releaseMonth;
            break;
          default:
            releaseDateReplace = releaseMonth + '/' + releaseDay;
            break;
        }
        var msg = site.translations.product[trKey];

        msg = msg.replace(/::release_date::/, releaseDateReplace);
        $ele.html(msg);
        $ele.show();
      }
    },
    setPreorderFlag: function (product_id, isValidPreorderSku) {
      var $preorderFlag = $(".js-product[data-product-id='" + product_id + "']").find(
        '.product__flag_preorder'
      );
      var $preorderSppContainer = $(".js-product[data-product-id='" + product_id + "']").closest(
        '.page-product'
      );

      $preorderFlag.hide();
      if (isValidPreorderSku) {
        $preorderFlag.css('display', 'inline-block');
        $preorderSppContainer.addClass('preorder_spp_container');
      } else {
        $preorderSppContainer.removeClass('preorder_spp_container');
      }

      var preorder_shade_product =
        typeof Drupal.settings.bb_product.pre_order_shade_badge !== 'undefined'
          ? Drupal.settings.bb_product.pre_order_shade_badge
          : '';

      if (preorder_shade_product) {
        var prodData = prodcat.data.getProduct(product_id);
        var allPreOrder = true;
        var skus = prodData && prodData.skus ? prodData.skus : null;

        if (!!skus) {
          $.each(skus, function (index, sku) {
            if (!sku.isPreOrder) {
              allPreOrder = false;

              return false;
            }
          });
          if (!allPreOrder) {
            $preorderFlag.find('.product__shade-preorder').addClass('product__shade-enable');
          }
        }
      }
    },
    setPreorderFlagForGrid: function () {
      $('.product-grid__item[data-product-id]').find('.product__flag_preorder').hide();
      var curDateStr = '' + Drupal.settings.server_date;

      $('.product-grid__item[data-product-id]').each(function () {
        var product_id = $(this).data('product-id');

        if (product_id) {
          arrProductInfo = prodcat.data.getProduct(product_id);
          // Replace old code with new isPreOrder logic and making the checking whether the default sku(First sku) is pre-order or not.
          var $addToPreOrderBtn = $(
            ".product-grid__item[data-product-id='" + product_id + "']"
          ).find('.js-add-to-cart');

          $(arrProductInfo).each(function () {
            if (this.skus.length > 0 && this.skus[0].isPreOrder) {
              $(".product-grid__item[data-product-id='" + product_id + "']")
                .find('.product__flag_preorder')
                .css('display', 'inline-block');
              $addToPreOrderBtn.html(site.translations.product.preorder);
              if (this.skus[0].INVENTORY_STATUS === 7) {
                $addToPreOrderBtn
                  .addClass('button--disabled product__soldout-preorder')
                  .data('disabled', true);
              }
            }
          });
        }
      });
    }
  };
  $(window).on('inv_status_data:updated', function () {
    Drupal.behaviors.notifyMeV1.setPreorderFlagForGrid();
  });
})(jQuery, window.site || {});
